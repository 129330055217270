import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { AddressForm } from '../common/form/AddressForm';
import { store } from '../initStore';
import { DBAFields, StoreTypes } from '../../interfaces';

import { emailRegExp, phoneRegExp, urlRegExp, wholeNumRegExp, poBoxRegExp } from '../../utils/regex';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm'

interface IDbaFormState {
    fields: DBAFields;
    errors: DBAFields;
    activeElement: string;
}

const explainer = () =>
    <>
        <h1>Tell us where you do business!</h1>
        <p>Now that we know a bit more about you, we want to learn the details of your business. Let’s start with where your business operates, and your DBA information.</p>
    </>

const useDBAInformationState = (props: ViewProps) => {
    const initialValues = { phone: '', email: '', website: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', isLegalAddress: 'yes' };
    const initialErrors = { phone: '', email: '', website: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', isLegalAddress: '' };

    const newState: IDbaFormState = {
        fields: props.dbaInformation ? { ...props.dbaInformation } : { ...initialValues },
        errors: initialErrors,
        activeElement: null
    }

    const [dbaInformationState, setDBAInformationState] = React.useState(newState)
    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setDBAInformationState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'dbaInformation', payload: { ...dbaInformationState.fields } });

            if (props.isBusiness) props.history.push('/instant/business/legal');
            if (props.isPersonal) props.history.push('/instant/personal/legal');
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for DBAInformation step`, fields: dbaInformationState.errors });
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setDBAInformationState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleUrlBlur() {
        const { website } = dbaInformationState.fields;

        if (!website) return;

        if (website.indexOf('https://') !== 0) {
            setDBAInformationState(prevState => { return { ...prevState, fields: { ...prevState.fields, website: `https://${website}` } } });
        }
    }

    function formIsValid() {
        const {
            fields: {
                zipcode,
                website,
                phone,
                email,
                addressLineOne,
            },
        } = dbaInformationState;

        const newErrors = {} as DBAFields;

        if (!wholeNumRegExp.test(zipcode)) {
            newErrors.zipcode = 'Invalid: Must be a number';
        }

        if (website !== '') {
            if (!urlRegExp.test(website)) newErrors.website = 'Invalid format';
        }

        if (!phoneRegExp.test(phone)) {
            newErrors.phone = 'Invalid Phone Number';
        }

        if (!emailRegExp.test(email)) {
            newErrors.email = 'Invalid Email Address';
        }

        if (poBoxRegExp.test(addressLineOne)) {
            newErrors.addressLineOne = 'Physical address cannot be a PO Box.';
        }

        setDBAInformationState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    return {
        dbaInformationState,
        handleChange,
        handleSubmit,
        handleCleaveChange,
        handleUrlBlur
    }
}

const DBAInformation = (props: ViewProps) => {

    const {
        dbaInformationState,
        handleChange,
        handleSubmit,
        handleCleaveChange,
        handleUrlBlur
    } = useDBAInformationState(props);

    const { fields, errors } = dbaInformationState;
    const { phone, email, website, isLegalAddress } = dbaInformationState.fields;

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={dbaInformationState.activeElement} />
        <form className="gp-form" id="#dbaInformation" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />

                <div className="form-group">
                    <AddressForm
                        title="Physical Business Address"
                        fields={fields}
                        errors={errors}
                        handleChange={handleChange} />

                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Business Email</label>
                        <input value={email} onChange={handleChange} name="email" type="email" maxLength={40} required />
                        {dbaInformationState.errors.email && <div className="error-message">{dbaInformationState.errors.email}</div>}
                    </div>

                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Business Phone</label>
                        <Cleave
                            name="phone"
                            value={phone}
                            options={{ phone: true, phoneRegionCode: 'us', blocks: [3, 3, 4], delimiter: '-' }}
                            onChange={handleCleaveChange}
                            required
                            inputMode="numeric" />
                        {dbaInformationState.errors.phone && <div className="error-message">{dbaInformationState.errors.phone}</div>}
                    </div>

                    <div className="input-third">
                        <label>Business Website</label>
                        <input value={website} onChange={handleChange} onBlur={handleUrlBlur} type="url" maxLength={40} name="website" />
                        {dbaInformationState.errors.website && <div className="error-message">{dbaInformationState.errors.website}</div>}
                    </div>
                </div>

                <fieldset className="form-group">
                    <IsOrRadioForm
                        label="Is this also your legal business address?"
                        name="isLegalAddress"
                        selected={isLegalAddress}
                        handleChange={handleChange} />
                </fieldset>

                <button type="submit" disabled={loading} className="submit ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ dbaInformation: state.dbaInformation });

export default connect(mapStateToProps)(withRouter(DBAInformation));
