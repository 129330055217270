import React, { useState } from 'react';
import { withRouter } from 'react-router';

import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';

import View, { ViewProps } from '../common/View';
import { purgeStore, store } from '../initStore';

import { signingDocuments, startPayment, formatInstantToCheckout, createAccountFromStore } from '../../api';
import { CheckoutFields, StoreTypes } from '../../interfaces';
import { wholeNumRegExp } from '../../utils/regex';
import { AddressForm } from '../common/form/AddressForm'

import redirect from '../../redirect'

interface ICheckoutFormState {
    fields: CheckoutFields;
    errors: CheckoutFields;
    creatingAccount: boolean;
    openingEmergepay: boolean;
    timer: any;
    counter: number;
    loading: boolean;
}

const explainer = () =>
    <>
        <h1>Checkout</h1>
        <p>Enter your billing information and review your order. When you’re done, click “continue” to securely process your transaction.</p>
    </>

function useInterval(callback, delay: null | number) {
    const savedCallback = React.useRef();

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            //@ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const useCheckoutState = (props: ViewProps) => {
    const initialValues = { billingName: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '' };

    const [checkoutState, setCheckoutState] = useState(
        {
            // @ts-ignore
            fields: props.checkout ? { ...props.checkout } : { ...initialValues },
            errors: initialValues,
            creatingAccount: false,
            openingEmergepay: false,
            timer: null,
            counter: 0,
            loading: false,
        } as ICheckoutFormState
    )

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;

            setCheckoutState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'checkout', payload: { ...checkoutState.fields } });

            if (!checkoutState.creatingAccount) {
                // Set creatingAccount to true to prevent multiple account creation.
                setCheckoutState(prevState => { return { ...prevState, creatingAccount: true } })
                handlePayment();
            }
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for checkout step`, fields: checkoutState.errors });
        }
    }

    React.useEffect(
        () => {
            // @ts-ignore
            window.emergepay.init();

            const { loading = false } = store.getState() as StoreTypes;

            setCheckoutState(prevState => { return { ...prevState, loading } })

            return () => { setCheckoutState(prevState => { return { ...prevState, timer: null } }) }
        }, []
    )

    function formIsValid() {
        const {
            fields: {
                zipcode = '',
            },
        } = checkoutState;

        const newErrors = {} as CheckoutFields;

        if (!wholeNumRegExp.test(zipcode)) {
            newErrors.zipcode = 'Invalid: Must be a number';
        }

        setCheckoutState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    async function handlePayment() {
        // @ts-ignore
        window._LTracker.push('Entering handlePayment');

        try {
            const { account } = store.getState() as StoreTypes;

            if (!account || !account.submitted) {
                const res = await createAccountFromStore();
                store.dispatch({ type: 'account', payload: { id: res.appId, submitted: true } });

                // @ts-ignore
                window._LTracker.push(`Successfully created account ${res.appId}`);
            }

            if (!checkoutState.openingEmergepay) {
                const { account: refAccount } = store.getState() as StoreTypes;

                if (!refAccount) {
                    throw new Error('Account is not defined')
                }

                const instant = formatInstantToCheckout(store.getState());

                const { transactionToken } = await startPayment(instant);

                setCheckoutState(prevState => { return { ...prevState, openingEmergepay: true } })

                // @ts-ignore
                window.emergepay.open({
                    // (required) Used to set up the modal
                    transactionToken,
                    // (optional) Callback function that gets called after a successful transaction
                    onTransactionSuccess: approvalData => {
                        // @ts-ignore
                        window.emergepay.close();

                        // @ts-ignore
                        window._LTracker.push(`Transaction Succeeded for ${refAccount.id}`);

                        loading(true)

                        setCheckoutState(prevState => { return { ...prevState, timer: 1000 } })
                    },
                    onTransactionFailure: failureData => {
                        setCheckoutState(prevState => { return { ...prevState, creatingAccount: false, openingEmergepay: false } })

                        loading(false)

                        // @ts-ignore
                        window._LTracker.push(`Transaction failed ${refAccount.id}`);

                        setTimeout(() => handlePayment(), 3000)
                    },
                    onTransactionCancel: () => {
                        setCheckoutState(prevState => { return { ...prevState, creatingAccount: false, openingEmergepay: false } })

                        loading(false)

                        // @ts-ignore
                        window._LTracker.push(`Transaction canceled ${refAccount.id}`);
                    },
                });
            }

        } catch (e) {
            // @ts-ignore
            window._LTracker.push({ text: `Error happened during checkout`, error: e });

            await purgeStore();

            redirect(process.env.REACT_APP_INSTANT_ERROR);
        }
    }

    function loading(isLoading) {
        store.dispatch({ type: 'loading', payload: { loading: isLoading } });

        setCheckoutState(prevState => { return { ...prevState, loading: isLoading } })
    }

    useInterval(getSigningDocuments, checkoutState.timer)

    async function getSigningDocuments() {
        // @ts-ignore
        window._LTracker.push('Attempting to fetching the signing documents');
        // Retry to get the signing URL for 30 times.

        if (checkoutState.counter < 30) {
            try {
                const { account } = store.getState() as StoreTypes;

                const { signingUrl } = await signingDocuments({ id: account.id });

                setCheckoutState(prevState => { return { ...prevState, timer: null } });

                // @ts-ignore
                window._LTracker.push(`Redirecting user to signing documents ${account.id}`);
                redirect(signingUrl);

            } catch (e) {
                // We can get a 404 here.
            }

            setCheckoutState(prevState => { return { ...prevState, counter: prevState.counter + 1 } })
        } else {
            setCheckoutState(prevState => { return { ...prevState, timer: null } })
            redirect(process.env.REACT_APP_INSTANT_ERROR);
        }
    }

    return {
        checkoutState,
        handleChange,
        handleSubmit
    }
}

const Checkout = (props: ViewProps) => {

    const {
        checkoutState,
        handleChange,
        handleSubmit
    } = useCheckoutState(props);

    const { products, selectedProducts } = store.getState() as StoreTypes;

    if (!selectedProducts) {
        props.history.push('/instant/product');
        return null;
    }

    const { fields, errors, loading } = checkoutState;

    const totalCost = Object.keys(selectedProducts).reduce((acc, product) => {
        const quantity = selectedProducts[product];

        if (+quantity > 0) {
            acc += quantity * products[product].price;
        }

        return acc
    }, 0);

    return <View>
        <Aside explainer={explainer()} field={'checkout'} />
        <form className="gp-form" id="#checkout" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={4} />

                <div className="form-group">
                    <div className="input-full">
                        <label>Billing Name</label>
                        <input className="input-half" type="text" name="billingName" onChange={handleChange} required />
                    </div>
                    <AddressForm
                        title="Billing Address"
                        fields={fields}
                        errors={errors}
                        handleChange={handleChange} />
                </div>

                <table className="tl mb3 w-100">
                    <thead className="bb">
                        <tr>
                            <th className="b">
                                Product
                            </th>
                            <th className="b">
                                Quantity
                            </th>
                            <th className="b">
                                Cost*
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(selectedProducts).map((product, i) => {
                            const quantity = selectedProducts[product];

                            if (+quantity > 0) {
                                let label;
                                let amount;

                                switch (product) {
                                    case 'cloverMini':
                                        label = 'Clover Mini'
                                        amount = +quantity * 439
                                        break;
                                    case 'cloverGo':
                                        label = 'Clover Go'
                                        amount = +quantity * 49
                                        break;
                                    case 'fd130':
                                        label = 'FD130'
                                        amount = +quantity * 325
                                        break;
                                    case 'cloverFlex':
                                        label = 'Clover Flex'
                                        amount = +quantity * 435
                                        break;
                                    case 'swipeSimpleBasic':
                                        label = 'Swipe Simple (Gateway Only)'
                                        amount = +quantity * 0
                                        break;
                                    case 'swipeSimple':
                                        label = 'Swipe Simple'
                                        amount = +quantity * 100
                                        break;
                                    default:
                                        break;
                                }

                                return <tr className="f5" key={i}>
                                    <td>{label}</td>
                                    <td>{quantity}</td>
                                    <td>{amount.toLocaleString('en-us', { style: 'currency', currency: 'usd' })}</td>
                                </tr>
                            }

                            return null
                        })}
                    </tbody>
                    <tfoot className="bt">
                        <tr>
                            <td />
                            <td />
                            <td>
                                <h5>{totalCost.toLocaleString('en-us', { style: 'currency', currency: 'usd' })}</h5>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div className="flex flex-column flex-row-l ml-auto items-center mt-auto">
                    <span className="f7 w5 db ml-auto tr-l ml-auto tc pr3-l mr0-l">* Tax and shipping included.</span>
                    <button type="submit" disabled={loading} className="submit db ml0 ml-auto ml0-l mr0-l mt2 self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}

export default withRouter(Checkout)
