import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router'

interface IStepIndicatorProps extends RouteComponentProps<{}> {
    pageStep: number;
}

export const StepIndicator = ({ pageStep }: IStepIndicatorProps) => {

    // @ts-ignore
    const pages = [...Array(6).keys()].slice(1).map((page, i) => {
        const stage = i === pageStep ? 'current' : i < pageStep ? 'complete' : '';

        return <li key={page} className={stage}>{stage === 'complete' ? <img src="/img/icon-checkmark.svg" alt="" /> : page}</li>
    })

    return <>
        <ul className="gp-form-elements__page-indicator">
            {pages}
        </ul>
    </>
}

export default withRouter(StepIndicator)
