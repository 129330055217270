import React from 'react';
import { BusinessOwnerType } from '../../../interfaces';

export interface IBusinessOwnerCard extends BusinessOwnerType {
    id: string;
    firstName: string;
    lastName: string;
    percentOwnership: string;
    destroyOwner(id: string): void;
}

const BusinessOwnerCard = (props: IBusinessOwnerCard) => {
    const { id, firstName, lastName, percentOwnership, destroyOwner } = props;
    return (
        <div className="flex w-100 w-40-m w-40-l ml1 mr3 ba b--black-40 pa2 br2 mb3 bg-near-white justify-between">
            <div className="w-80">
                <p className="b">{`${firstName} ${lastName}`}</p>
                <p>Ownership: {percentOwnership}%</p>
            </div>

            <span className="h-100 flex items-center w-20 justify-center pointer" onClick={() => destroyOwner(id)}>
                <i className="material-icons">
                highlight_off
                </i>
            </span>
        </div>
    )
}

export default BusinessOwnerCard;
