import React from 'react';
import { withRouter } from 'react-router';
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux';

import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { Dropdown } from '../common/Dropdown';
import { IndustryCategories, IndustryTypes } from '../models';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm';
import { store } from '../initStore';
import { ProcessingFormFields, StoreTypes } from '../../interfaces';

import { wholeNumRegExp } from '../../utils/regex';

interface IErrors extends Partial<ProcessingFormFields> {
    percentageTotal: string;
}
interface IProcessingProfileFormState {
    fields: ProcessingFormFields;
    errors: IErrors;
    activeElement: string;
}

const explainer = () =>
    <>
        <h1>TELL US ABOUT YOUR CARD VOLUME</h1>
        <p>In order to get you set up, we need to know what kind of business you run and what your credit card sales look like each month.</p>
    </>

const useProcessingState = (props: ViewProps) => {
    const initialValues = { businessCategory: '', businessType: '', businessDescription: '', currentlyAcceptingCards: 'no', swipedPercentage: 0, keyedPercentage: 0, motoPercentage: 0, ecommPercentage: 0, averageTicket: '', averageMonthlyCardVolume: '', averageMonthlySalesVolume: '' };
    const initialErrors = { businessCategory: '', businessType: '', businessDescription: '', currentlyAcceptingCards: '', swipedPercentage: 0, keyedPercentage: 0, motoPercentage: 0, ecommPercentage: 0, averageTicket: '', averageMonthlyCardVolume: '', averageMonthlySalesVolume: '' };

    const [processingState, setProcessingState] = React.useState(
        {
            fields: props.processing ? { ...initialValues, ...props.processing } : { ...initialValues },
            errors: initialErrors,
            activeElement: null
        } as IProcessingProfileFormState
    )

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setProcessingState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } as any } });
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;

            setProcessingState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } as any } });
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'processing', payload: { ...processingState.fields } });
            props.history.push('/instant/banking')
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for processing step`, fields: processingState.errors });
        }
    };


    function formIsValid() {
        const {
            fields: {
                swipedPercentage,
                keyedPercentage,
                motoPercentage,
                ecommPercentage,
                averageMonthlyCardVolume,
                averageMonthlySalesVolume,
                averageTicket,
            },
        } = processingState;

        const newErrors = {} as IErrors;
        const percentageTotal = +swipedPercentage + +keyedPercentage + +motoPercentage + +ecommPercentage;

        if (percentageTotal > 100) {
            newErrors.percentageTotal = 'Processing Total is greater than 100%'
        } else if (percentageTotal < 100) {
            newErrors.percentageTotal = 'Processing Total is less than 100%';
        }

        if (!wholeNumRegExp.test(swipedPercentage.toString()) || !wholeNumRegExp.test(keyedPercentage.toString()) || !wholeNumRegExp.test(motoPercentage.toString()) || !wholeNumRegExp.test(ecommPercentage.toString())) newErrors.percentageTotal = 'Round to the nearest number';

        if (!wholeNumRegExp.test(averageMonthlySalesVolume)) newErrors.averageMonthlySalesVolume = 'Must be a number';
        if (!wholeNumRegExp.test(averageMonthlyCardVolume)) newErrors.averageMonthlyCardVolume = 'Must be a number';
        if (!wholeNumRegExp.test(averageTicket)) newErrors.averageTicket = 'Must be a number';

        setProcessingState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';
        setProcessingState(prevState => { return { ...prevState, activeElement: name } })
    }

    function elementBlur(e) {
        setProcessingState(prevState => { return { ...prevState, activeElement: null } })
    }

    return {
        processingState,
        handleChange,
        handleCleaveChange,
        handleSubmit,
        elementActive,
        elementBlur,
    }
}

const Processing = (props: ViewProps) => {
    const {
        processingState,
        handleChange,
        handleCleaveChange,
        handleSubmit,
        elementActive,
        elementBlur,
    } = useProcessingState(props);

    const { loading = false } = store.getState() as StoreTypes;

    const { businessCategory, businessType, currentlyAcceptingCards, swipedPercentage, keyedPercentage, motoPercentage, ecommPercentage, averageMonthlyCardVolume, averageMonthlySalesVolume, averageTicket } = processingState.fields;

    return <View>
        <Aside explainer={explainer()} field={processingState.activeElement} />
        <form className="gp-form" id="#processing" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={2} />

                <div className="form-group">
                    <div className="input-half">
                        <Dropdown
                            selected={businessCategory}
                            selectLabel="Business Category"
                            selectName="businessCategory"
                            firstOption="Select a category..."
                            options={IndustryCategories}
                            onChange={handleChange}
                            className="input-full"
                            required />
                    </div>

                    <div className="input-half">
                        <Dropdown
                            selected={businessType}
                            selectLabel="Business Type"
                            selectName="businessType"
                            firstOption="Select a type..."
                            options={IndustryTypes[businessCategory]}
                            onChange={handleChange}
                            className="input-full"
                            required />
                    </div>

                    {
                        businessType === 'other' &&
                        <div>
                            <label>If other, please describe your business. (150 Character Limit)</label>
                            <input type="text" name="businessDescription" onChange={handleChange} maxLength={150} required />
                        </div>
                    }

                </div>

                <label>Estimate Processing by Percentage (Total = 100%)</label>
                <div className="form-group">

                    <div className="input-half">
                        <label className="db">Swiped Percentage</label>
                        <input name="swipedPercentage" onFocus={elementActive} onBlur={elementBlur} value={swipedPercentage} onChange={handleChange} type="number" max={100} required />
                    </div>
                    <div className="input-half">
                        <label className="db">Keyed Percentage</label>
                        <input name="keyedPercentage" onFocus={elementActive} onBlur={elementBlur} value={keyedPercentage} onChange={handleChange} type="number" max={100} required />
                    </div>
                    <div className="input-half">
                        <label className="db">MOTO/Phone Order Percentage</label>
                        <input name="motoPercentage" onFocus={elementActive} onBlur={elementBlur} value={motoPercentage} onChange={handleChange} type="number" max={100} required />
                    </div>
                    <div className="input-half">
                        <label className="db">Ecommerce Percentage</label>
                        <input name="ecommPercentage" onFocus={elementActive} onBlur={elementBlur} value={ecommPercentage} onChange={handleChange} type="number" max={100} required />
                    </div>
                    {processingState.errors.percentageTotal && <div className="error-message">{processingState.errors.percentageTotal}</div>}
                </div>

                <div className="form-group">
                    <div className="input-full">
                        <label>Estimate your total monthly sales volume (cash, checks, credit/debit cards, etc.)</label>
                        <Cleave
                            className="input-half"
                            name="averageMonthlySalesVolume"
                            value={averageMonthlySalesVolume}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {processingState.errors.averageMonthlySalesVolume && <div className="error-message">{processingState.errors.averageMonthlySalesVolume}</div>}
                    </div>
                    <div className="input-full">
                        <label>Estimate just your total monthly sales from credit and debit cards.</label>
                        <Cleave
                            className="input-half"
                            name="averageMonthlyCardVolume"
                            value={averageMonthlyCardVolume}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {processingState.errors.averageMonthlyCardVolume && <div className="error-message">{processingState.errors.averageMonthlyCardVolume}</div>}
                    </div>
                    <div className="input-full">
                        <label>Estimate your average transaction size</label>
                        <Cleave
                            className="input-half"
                            name="averageTicket"
                            value={averageTicket}
                            options={{
                                prefix: '$',
                                rawValueTrimPrefix: true,
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralDecimalScale: 0,
                            }}
                            onChange={handleCleaveChange}
                            maxLength={10}
                            required
                            inputMode="numeric" />
                        {processingState.errors.averageTicket && <div className="error-message">{processingState.errors.averageTicket}</div>}
                    </div>

                    <div className="input-full">
                        <IsOrRadioForm label="Currently accepting cards?" name="currentlyAcceptingCards" selected={currentlyAcceptingCards} handleChange={handleChange} />
                    </div>
                </div>

                <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ processing: state.processing });

export default connect(mapStateToProps)(withRouter(Processing));
