import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { store } from '../initStore';
import { Dropdown } from '../common/Dropdown';
import { LegalEntityTypes } from '../models';
import { AddressForm } from '../common/form/AddressForm';
import { LegalInformationFields, StoreTypes } from '../../interfaces';

import { wholeNumRegExp } from '../../utils/regex';

interface IInformationState {
    fields: LegalInformationFields;
    errors: LegalInformationFields;
    activeElement: string;
}

const explainer = () =>
    <>
        <h1>TELL US WHO OWNS THE BUSINESS</h1>
        <p>Great, now tell us a bit about the legal structure of your business. </p>
    </>

const useLegalInformationState = (props: ViewProps) => {
    const initialValues = { entityType: '', legalName: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', tin: '', yearsInBusiness: '' };
    const initialErrors = { entityType: '', legalName: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', tin: '', yearsInBusiness: '' };
    const initialFieldValues = initialValues;

    if (props.isPersonal) {
        initialFieldValues.entityType = 'Sole Proprietorship';
    }

    const [legalInformationState, setLegalInformationState] = React.useState(
        {
            fields: props.legalInformation ? { ...props.legalInformation } : { ...initialFieldValues },
            errors: initialErrors,
            activeElement: null,
        } as IInformationState
    )

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setLegalInformationState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    async function handleSubmit(e: React.FormEvent): Promise<any> {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'legalInformation', payload: { ...legalInformationState.fields } });

            const {
                isBusiness: isBusiness = false,
                isPersonal: isPersonal = false,
            } = props

            if (isBusiness) {
                props.history.push('/instant/business/owners')
            } else if (isPersonal) {
                props.history.push('/instant/personal/owner')
            }
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for LegalInformation step`, fields: legalInformationState.errors });
        }
    }

    function formIsValid() {
        const {
            fields: {
                tin,
                yearsInBusiness
            },
        } = legalInformationState;

        const newErrors = {} as LegalInformationFields;

        if (props.isBusiness) {
            if (tin.length !== 9 || !wholeNumRegExp.test(tin)) newErrors.tin = 'Must be a 9 digit number';
        }

        if (props.isPersonal && tin) {
            if (tin.length !== 9 || !wholeNumRegExp.test(tin)) newErrors.tin = 'Must be a 9 digit number';
        }

        if (!wholeNumRegExp.test(yearsInBusiness)) newErrors.yearsInBusiness = 'Round to the nearest number';

        setLegalInformationState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    function elementActive(e) {
        if (e.target) {
            setLegalInformationState(prevState => { return { ...prevState, activeElement: e.target ? e.target.name : '' } })
        }
    }

    return {
        legalInformationState,
        handleChange,
        handleSubmit,
        elementActive
    }
}

const LegalInformation = (props: ViewProps) => {
    const {
        legalInformationState,
        handleChange,
        handleSubmit,
        elementActive
    } = useLegalInformationState(props);

    const { entityType, legalName, tin, yearsInBusiness } = legalInformationState.fields;
    const { fields, errors } = legalInformationState;

    const { dbaInformation } = store.getState() as StoreTypes;

    if (!dbaInformation) {
        props.history.push('/instant/signup');
        return null;
    }

    const { isLegalAddress = 'no' } = dbaInformation

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={legalInformationState.activeElement} />
        <form className="gp-form" id="#legalInformation" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />

                <div className="form-group">
                    {
                        props.isBusiness &&
                        <div className="input-full">
                            <label>Legal Business Name</label>
                            <input className="input-half" value={legalName} onChange={handleChange} placeholder="ABC Company, LLC" type="text" name="legalName" maxLength={30} required />
                        </div>
                    }

                    {
                        props.isBusiness &&
                        <Dropdown
                            selected={entityType}
                            selectLabel="Legal Entity Type"
                            selectName="entityType"
                            firstOption="Select a type..."
                            options={LegalEntityTypes}
                            className="input-third"
                            onChange={handleChange}
                            required={props.isBusiness} />
                    }

                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>TIN/EIN</label>
                        <input value={tin} onFocus={elementActive} onChange={handleChange} type="text" name="tin" maxLength={9} minLength={9}/>
                        {legalInformationState.errors.tin && <div className="error-message">{legalInformationState.errors.tin}</div>}
                    </div>

                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Years in Business</label>
                        <input value={yearsInBusiness} onChange={handleChange} name="yearsInBusiness" max="300" type="number" inputMode="numeric" required />
                        {legalInformationState.errors.yearsInBusiness && <div className="error-message">{legalInformationState.errors.yearsInBusiness}</div>}
                    </div>

                    {isLegalAddress === 'no' ?
                        <AddressForm
                            title="Legal Business Address"
                            fields={fields}
                            errors={errors}
                            className="input-full"
                            handleChange={handleChange} /> : null}

                </div>

                <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ legalInformation: state.legalInformation });

export default connect(mapStateToProps)(withRouter(LegalInformation));

