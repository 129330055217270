import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ChangeEvent } from 'cleave.js/react/props';

import Aside from '../../common/Aside';
import StepIndicator from '../../common/StepIndicator';
import View, { ViewProps } from '../../common/View';
import BusinessOwnerForm from '../../common/form/BusinessOwnerForm';
import { BusinessOwnerType, StoreTypes } from '../../../interfaces';
import { store } from '../../initStore';

import { wholeNumRegExp, emailRegExp } from '../../../utils/regex';


interface IOwnerState {
    fields: BusinessOwnerType;
    errors: BusinessOwnerType;
    dobRef: React.ReactInstance;
    ssnRef: React.ReactInstance;
    activeElement: string;
}

const explainer = () =>
    <>
        <h1>Personal Information</h1>
        <p>Your personal info is required by the IRS and payment-card companies.</p>
    </>

const useOwnerState = (props: ViewProps) => {

    const initialValues = { firstName: '', lastName: '', title: 'Owner', ssn: '', percentOwnership: '100', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', dob: '', email: '' };
    const initialErrors = { firstName: '', lastName: '', title: '', ssn: '', percentOwnership: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', dob: '', email: '' };

    const [ownerState, setOwnerState] = React.useState(
        {
            fields: props.owners ? { ...props.owners[0] } : { ...initialValues },
            errors: initialErrors,
            dobRef: null,
            ssnRef: null,
            activeElement: null,
        } as IOwnerState
    )


    function onDOBInit(cleave: React.ReactInstance) {
        if (cleave) setOwnerState(prevState => { return { ...prevState, dobRef: cleave } });
    }

    function onSSNInit(cleave: React.ReactInstance) {
        if (cleave) setOwnerState(prevState => { return { ...prevState, ssnRef: cleave } });
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setOwnerState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleDOBChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            const value = e.target.value;
            setOwnerState(prevState => { return { ...prevState, fields: { ...prevState.fields, dob: value.trim() } } });
        }
    }

    function handleSSNChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            const value = e.target.rawValue || e.target.value;
            setOwnerState(prevState => { return { ...prevState, fields: { ...prevState.fields, ssn: value.trim() } } });
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'owners', payload: [{ ...ownerState.fields }] });

            const { legalInformation } = store.getState() as StoreTypes;

            const {
                firstName,
                lastName,
            } = ownerState.fields

            legalInformation.legalName = `${firstName} ${lastName}`

            store.dispatch({ type: 'legalInformation', payload: legalInformation });

            props.history.push({ pathname: '/instant/processing' });
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for personal owners step`, fields: ownerState.errors });
        }
    };

    function formIsValid() {
        const { email, zipcode, dob, ssn } = ownerState.fields;

        const newErrors = {} as BusinessOwnerType;

        if (!emailRegExp.test(email)) {
            newErrors.email = 'Invalid Email Address';
        }

        if (!wholeNumRegExp.test(zipcode)) {
            newErrors.zipcode = 'Invalid: Must be a number';
        }

        if (dob.length !== 10) {
            newErrors.dob = 'Invalid Date of Birth. Format must follow MM-DD-YYYY';
        }

        const dateNow = Date.now() - new Date(dob).getTime();
        const age = Math.abs(new Date(dateNow).getUTCFullYear() - 1970);

        if (age < 18 || age > 120) {
            newErrors.dob = 'Invalid Date of Birth. You must be at least 18 and still alive';
        }

        if (ssn.length !== 9) {
            newErrors.ssn = 'Invalid Social Security Number. Format must follow XXX-XX-XXXX';
        }

        setOwnerState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';

        setOwnerState(prevState => { return { ...prevState, activeElement: name } })
    }

    function elementBlur(e) {
        setOwnerState(prevState => { return { ...prevState, activeElement: null } })
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;

            setOwnerState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }
    return {
        ownerState,
        onDOBInit,
        onSSNInit,
        handleChange,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        elementActive,
        elementBlur,
        handleCleaveChange
    }
}

const Owner = (props: ViewProps) => {
    const {
        ownerState,
        onDOBInit,
        onSSNInit,
        handleChange,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        elementActive,
        elementBlur,
        handleCleaveChange
    } = useOwnerState(props);

    const { fields, errors } = ownerState;

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={ownerState.activeElement} />
        <form className="gp-form" id="#personalOwners" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <BusinessOwnerForm
                    onDOBInit={onDOBInit}
                    onSSNInit={onSSNInit}
                    handleChange={handleChange}
                    handleDOBChange={handleDOBChange}
                    handleSSNChange={handleSSNChange}
                    handleFocus={elementActive}
                    handleBlur={elementBlur}
                    handleCleaveChange={handleCleaveChange}
                    fields={fields}
                    errors={errors}
                    type="Personal"
                />
                <button type="submit" disabled={loading} className="submit mt-auto ml-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ owners: state.owners });

export default connect(mapStateToProps)(withRouter(Owner));
