import axios from 'axios';

import { ICheckout, ISigning, IAccount, IProduct, ILead } from './gravity-instant';
import { StoreTypes, BusinessOwnerType } from '../interfaces';
import { store } from '../components/initStore';

axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_INSTANT_KEY;

export const getPartnerConfig = async (partnerName: string): Promise<any> => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_GRAVITY_INSTANT}/partner/${partnerName}`);
        return data;
    }
    catch {
    }
}

export const createLead = async (lead: ILead): Promise<any> => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_GRAVITY_INSTANT}/lead/capture`, lead)
        return res.data;
    } catch (err) {
        console.error(err);
    }
}

export const createLeadFromStore = () => {
    const lead: ILead = formatInstantLead(store.getState() as StoreTypes);

    if (lead) {
        return createLead(lead);
    }
}

interface ICheckoutResponse {
    transactionToken: string;
}

export const startPayment = async (product: ICheckout): Promise<any> => {
    store.dispatch({ type: 'loading' });

    const response = await axios.post(`${process.env.REACT_APP_GRAVITY_INSTANT}/checkout/startPayment`, product);

    store.dispatch({ type: 'loading' });

    return response.data as ICheckoutResponse;
}

interface IAccountResponse {
    success: boolean;
    appId: string;
}

export const createAccount = async (account: IAccount): Promise<any> => {
    store.dispatch({ type: 'loading' });

    try {
        const res = await axios.post(`${process.env.REACT_APP_GRAVITY_INSTANT}/account/create`, account);
        return res.data;
    } catch (err) {
        console.error(err);
    }

    store.dispatch({ type: 'loading' });
}

export const createAccountFromStore = (): Promise<any> => {
    const account: IAccount = formatInstantToAccountAPI((store.getState() as StoreTypes));
    return createAccount(account);
}

interface ISigningResponse {
    signingUrl: string;
}

export const signingDocuments = async (signing: ISigning): Promise<any> => {
    const response = await axios.post(`${process.env.REACT_APP_GRAVITY_INSTANT}/account/signing`, signing)

    return response.data as ISigningResponse;
}

const formatProducts = (products): [IProduct] | null => {
    if (products) {
        return Object.keys(products).reduce((acc, product) => {
            if (+products[product] > 0) {
                const { products: storeProducts } = store.getState() as StoreTypes;

                acc.push({
                    code: storeProducts[product].identifier,
                    qty: +products[product],
                    purchase: true,
                });
            }

            return acc;
        }, []) as unknown as [IProduct];
    }

    return null;
}

export const formatInstantLead = (store: StoreTypes) => {
    const { signup, utmParams } = store;

    if (signup) {
        return {
            firstName: signup.firstName,
            lastName: signup.lastName,
            email: signup.email,
            phone: signup.phone,
            company: signup.dba,
            UTM_Medium__c: utmParams.medium,
            UTM_Source__c: utmParams.source,
            UTM_Campaign__c: utmParams.campaign,
            UTM_Content__c: utmParams.content,
            UTM_Keyword__c: utmParams.keyword,
        } as ILead;
    }

    return null;
}

export const formatInstantToAccountAPI = (store: StoreTypes): IAccount => {
    const { signup, dbaInformation, legalInformation, owners, processing, banking, selectedProducts, utmParams, pricingCode } = store;

    const legalAddress = dbaInformation.isLegalAddress === 'yes'
        ? {
            line1: dbaInformation.addressLineOne,
            line2: dbaInformation.addressLineTwo,
            city: dbaInformation.city,
            state: dbaInformation.state,
            postalCode: dbaInformation.zipcode,
        }
        : {
            line1: legalInformation.addressLineOne,
            line2: legalInformation.addressLineTwo,
            city: legalInformation.city,
            state: legalInformation.state,
            postalCode: legalInformation.zipcode,
        };

    const allOwners = [...owners || []]
    const formattedOwners = allOwners.map((owner: BusinessOwnerType) => {
        const formattedDOB = [2, 0, 1].map(i => owner.dob.split('-')[i]).join('-');

        const ownerDefaultNumber = legalInformation.entityType === 'Sole Proprietorship' ? owner.phoneNumber || signup.phone : owner.phoneNumber;

        return {
            firstName: owner.firstName,
            lastName: owner.lastName,
            percentOwnership: +owner.percentOwnership,
            ssn: owner.ssn,
            dob: formattedDOB,
            title: owner.title,
            address: {
                line1: owner.addressLineOne,
                line2: owner.addressLineTwo,
                city: owner.city,
                state: owner.state,
                postalCode: owner.zipcode,
            },
            phone: ownerDefaultNumber,
            email: owner.email,
        }
    });

    const processingActivity =  {
        swiped: +processing.swipedPercentage,
        keyed: +processing.keyedPercentage,
        moto: +processing.motoPercentage,
        internet: +processing.ecommPercentage
    };

    const acceptingCards = processing.currentlyAcceptingCards === 'yes';

    const productSelected = formatProducts(selectedProducts);

    const account = {
        productPath: 'BUY',
        business: {
            dba: sfSafe(signup.dba),
            type: processing.businessType,
            goodsAndServices: sfSafe(processing.businessType),
            address: {
                line1: sfSafe(dbaInformation.addressLineOne),
                line2: sfSafe(dbaInformation.addressLineTwo),
                city: sfSafe(dbaInformation.city),
                state: sfSafe(dbaInformation.state),
                postalCode: dbaInformation.zipcode,
            },
            phone: dbaInformation.phone,
            email: dbaInformation.email,
            websiteUrl: dbaInformation.website,
        },
        legalEntity: {
            type: legalInformation.entityType,
            name: sfSafe(legalInformation.legalName),
            tin: legalInformation.tin,
            address: {
                line1: sfSafe(legalAddress.line1),
                line2: sfSafe(legalAddress.line2),
                city: sfSafe(legalAddress.city),
                state: sfSafe(legalAddress.state),
                postalCode: legalAddress.postalCode,
            },
            yearsInBusiness: +legalInformation.yearsInBusiness,
            foreign: false,
        },
        owners: [...formattedOwners],
        processingProfile: {
            averageMonthlySalesVolume: +processing.averageMonthlySalesVolume,
            averageMonthlyCardVolume: +processing.averageMonthlyCardVolume,
            averageTicket: +processing.averageTicket,
            currentlyAcceptingCards: acceptingCards,
            acceptanceMethods: processingActivity,
        },
        banking: {
            depositAccount: sfSafe(banking.depositAccount),
            depositRouting: sfSafe(banking.depositRouting),
        },
        products: productSelected !== null ? productSelected : [],
        pricingCode: pricingCode || 'DEFAULT',
        analytics: {
            utm: { ...utmParams },
        },
    } as IAccount;

    return account;
}

const sfSafe = str =>
    str.replace(/'|&|—|“|”|’|’|@|\+|\(|\)|%|#|\*|!|\||"|<|>|\?|\/|:|\[|\]|=|\\/g, '')

export const formatInstantToCheckout = store => {
    const { selectedProducts, checkout, account } = store;

    const items = formatProducts(selectedProducts);

    return {
        appId: account.id,
        billingName: checkout.billingName,
        zip: checkout.zipcode,
        address: checkout.addressLineOne,
        products: items,
    } as ICheckout;
};

