import React from 'react';
import { AddressForm } from './AddressForm';
import Cleave from 'cleave.js/react';
import { ChangeEvent } from 'cleave.js/react/props';
import { BusinessOwnerType } from '../../../interfaces';
import { OwnerTypes } from '../../models'
import { Dropdown } from '../Dropdown'

interface IBusinessOwner {
    fields: BusinessOwnerType;
    errors: BusinessOwnerType;
    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void;
    onDOBInit(cleave: React.ReactInstance): void;
    onSSNInit(cleave: React.ReactInstance): void;
    handleDOBChange(e: ChangeEvent<HTMLInputElement>): void;
    handleSSNChange(e: ChangeEvent<HTMLInputElement>): void;
    handleCleaveChange: any;
    handleFocus: any;
    handleBlur: any
    percentOwnership?: boolean;
    type: 'Business'|'Personal';
}

const BusinessOwnerForm = (props: IBusinessOwner) => {
    const { fields, errors, handleChange, handleCleaveChange, onDOBInit, handleDOBChange, onSSNInit, handleSSNChange, percentOwnership, handleFocus, handleBlur, type } = props;

    return (
        <div className="form-group">
            <div className="input-third">
                <label>Legal First Name</label>
                <input value={fields.firstName} onChange={handleChange} type="text" name="firstName" maxLength={40} required />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>
            <div className="input-third">
                <label>Legal Last Name</label>
                <input value={fields.lastName} onChange={handleChange} type="text" name="lastName" maxLength={80} required />
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>

            <div className="input-third">
                <label>Email</label>
                <input value={fields.email} onChange={handleChange} name="email" type="email" maxLength={40} required />
                {errors.email && <div className="error-message">{errors.email}</div>}
            </div>

            {type === 'Business' ?
                <>
                    <div className="input-third">
                        <label>Phone Number</label>
                        <Cleave
                            name="phoneNumber"
                            value={fields.phoneNumber}
                            options={{
                                phone: true,
                                phoneRegionCode: 'us',
                            }}
                            onChange={handleCleaveChange}
                            required
                            inputMode="numeric" />
                        {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                    </div>

                    <div className="input-third">
                        <Dropdown
                            selectLabel="Title"
                            selectName="title"
                            firstOption="Select a type..."
                            options={OwnerTypes}
                            selected={fields.title}
                            className="input-full"
                            onChange={handleChange}
                            required />
                    </div>
                </>
                : null
            }

            {
                percentOwnership &&
                <div className="input-third">
                    <label>Percent Ownership</label>
                    <input
                        name="percentOwnership"
                        placeholder="25 to 100"
                        type="number"
                        value={fields.percentOwnership}
                        max="100"
                        min="0"
                        onChange={handleChange}
                        required
                        inputMode="numeric" />
                    {errors.percentOwnership && <div className="error-message">{errors.percentOwnership}</div>}
                </div>
            }

            <AddressForm
                title="Address"
                fields={fields}
                errors={errors}
                handleChange={handleChange}
            />

            <div className="input-third">
                <label>Date of Birth</label>
                <Cleave
                    name="dob"
                    value={fields.dob}
                    placeholder="MM-DD-YYYY"
                    options={{
                        date: true,
                        delimiter: '-',
                        datePattern: ['m', 'd', 'Y'],
                    }}
                    onChange={handleDOBChange}
                    onInit={onDOBInit}
                    required
                    inputMode="numeric" />
                {errors.dob && <div className="error-message">{errors.dob}</div>}
            </div>

            <div className="input-third">
                <label>Social Security Number</label>
                <Cleave
                    name="ssn"
                    value={fields.ssn}
                    placeholder="xxx-xx-xxxx"
                    options={{
                        numericOnly: true,
                        blocks: [3, 2, 4],
                        delimiters: ['-', '-', '-'],
                    }}
                    onInit={onSSNInit}
                    onChange={handleSSNChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    required
                    inputMode="numeric" />
                {errors.ssn && <div className="error-message">{errors.ssn}</div>}
            </div>
        </div>
    )
}

export default BusinessOwnerForm;
