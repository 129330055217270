import { initStore } from './components/initStore'
import { StoreTypes } from './interfaces';

type TypeOptions = 'account' | 'signup' | 'dbaInformation' | 'legalInformation' | 'owners' | 'processing' | 'banking' | 'productPath' | 'products' | 'selectedProducts' | 'checkout' | 'loading' | 'partnerInfo' | 'reset' | 'SET_PRICING_CODE' | 'SET_PARTNER';

export const reducer = (state: StoreTypes = {} as StoreTypes, action: { type: TypeOptions; payload?: any }): StoreTypes => {
    const { type, payload } = action;

    switch (type) {
        case 'account':
            return { ...state, account: { ...state.createAccount, ...payload } };

        case 'signup':
            return {
                ...state,
                signup: { ...payload.signup },
                utmParams: { ...payload.utmParams },
            };

        case 'dbaInformation':
            return { ...state, dbaInformation: { ...payload } };

        case 'legalInformation':
            return { ...state, legalInformation: { ...payload } };

        case 'owners':
            return { ...state, owners: [...payload] };

        case 'processing':
            return { ...state, processing: { ...payload } };

        case 'banking':
            return { ...state, banking: { ...payload } };

        case 'productPath':
            return { ...state, products: { ...payload } };

        case 'products':
            return { ...state, products: { ...payload } };

        case 'selectedProducts':
            return { ...state, selectedProducts: { ...payload } };

        case 'checkout':
            return { ...state, checkout: { ...payload } };

        case 'loading':
            // @ts-ignore
            return { ...state, loading: payload ? payload.loading : !state.loading };

        case 'partnerInfo':
            return {
                ...state,
                partnerInfo: payload,
                pricingCode: payload.pricingCode || 'DEFAULT',
                processing: (payload.industry) ? {
                    businessCategory: payload.industry.category || '',
                    businessType: payload.industry.type || '',
                } : null
            };

        case 'SET_PRICING_CODE':
            return {
                ...state,
                pricingCode: payload.pricingCode || 'DEFAULT',
            }
        case 'reset':
            return initStore

        case 'SET_PARTNER':
        default:
            return state;
    }
}
