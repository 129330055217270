import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { store } from '../initStore';
import { BankingInfoFields, StoreTypes } from '../../interfaces';
import { wholeNumRegExp } from '../../utils/regex';
import { RoutingNumbers } from '../models'

interface IBankingFormState {
    fields: BankingInfoFields;
    errors: BankingInfoFields;
    activeElement: string;
}

const explainer = () =>
    <>
        <h1>TELL US YOUR BANK INFORMATION</h1>
        <p>Let us know the business checking account you want deposits sent to. This should be the same account as the voided check you’ll upload shortly.</p>
    </>

const useBankState = (props: ViewProps) => {
    const initialValues: BankingInfoFields = { depositAccount: '', depositRouting: '' };
    const initialErrors: BankingInfoFields = { depositAccount: '', depositRouting: '' };

    const [bankState, setBankState] = React.useState({
        fields: props.banking ? { ...props.banking } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    } as IBankingFormState)

    function handleFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist()
        e.preventDefault();
        const name: string = e.target ? e.target.name : '';

        setBankState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: e.target ? e.target.value : '' } } as IBankingFormState });
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({ type: 'banking', payload: { ...bankState.fields } });
            props.history.push('/instant/create-app');
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for banking step`, fields: bankState.errors });
        }
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';

        setBankState(prevState => { return { ...prevState, activeElement: name } })
    }

    function elementBlur(e) {
        setBankState(prevState => { return { ...prevState, activeElement: null } })
    }

    function formIsValid() {
        const {
            fields: {
                depositAccount,
                depositRouting,
            },
        } = bankState;

        const newErrors = {} as BankingInfoFields;

        if (!wholeNumRegExp.test(depositAccount)) {
            newErrors.depositAccount = 'Invalid: Must be a number';
        }

        if (!RoutingNumbers.includes(+depositRouting)) {
            newErrors.depositRouting = 'Invalid routing number';
        }

        setBankState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }
    return {
        bankState,
        handleFieldChange,
        handleSubmit,
        elementActive,
        elementBlur
    }
}

const Banking = (props: ViewProps) => {
    const {
        bankState,
        handleFieldChange,
        handleSubmit,
        elementActive,
        elementBlur
    } = useBankState(props);

    const { depositAccount, depositRouting } = bankState.fields;

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={bankState.activeElement} />
        <form className="gp-form" id="#banking" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={3} />
                <div className="form-group">
                    <div className="input-full">
                        <label>Deposit Routing Number</label>
                        <input onFocus={elementActive} onBlur={elementBlur} className="input-third" value={depositRouting} onChange={handleFieldChange} type="text" name="depositRouting" inputMode="numeric" required />
                        {bankState.errors.depositRouting && <div className="error-message">{bankState.errors.depositRouting}</div>}
                    </div>
                    <div className="input-full">
                        <label>Deposit Account</label>
                        <input onFocus={elementActive} onBlur={elementBlur} className="input-third" value={depositAccount} onChange={handleFieldChange} type="text" inputMode="numeric" maxLength={17} name="depositAccount" required />
                        {bankState.errors.depositAccount && <div className="error-message">{bankState.errors.depositAccount}</div>}
                    </div>
                </div>

                <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ banking: state.banking });

export default connect(mapStateToProps)(withRouter(Banking));
