import uuid from 'uuid/v4';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ChangeEvent } from 'cleave.js/react/props';

import Aside from '../../common/Aside';
import StepIndicator from '../../common/StepIndicator';
import View, { ViewProps } from '../../common/View';
import BusinessOwnerForm from '../../common/form/BusinessOwnerForm';
import BusinessOwnerCard, { IBusinessOwnerCard } from '../../common/form/BusinessOwnerCard';
import { BusinessOwnerType, StoreTypes } from '../../../interfaces';
import { store } from '../../initStore';

import { wholeNumRegExp, emailRegExp } from '../../../utils/regex';


interface IBusinessOwnersInfoFormState {
    fields: BusinessOwnerType;
    errors: BusinessOwnerType;
    owners: BusinessOwnerType[];
    dobRef: React.ReactInstance;
    ssnRef: React.ReactInstance;
    activeElement: string;
    showForm: boolean;
}

const explainer = () =>
    <>
        <h1>TELL US WHO OWNS THE BUSINESS</h1>
        <p>It's important to us to know who our clients are, plus its required by the government.</p>
    </>

const Directions = () =>
    <>
        <p className="b f6">You’ll need to add at least one owner before continuing.</p>
        <p className="f7">Please list everyone who owns 25% or more of the business.</p>
    </>

const useOwnersState = (props: ViewProps) => {
    const initialValues = { firstName: '', lastName: '', phoneNumber: '', title: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', ssn: '', percentOwnership: '', dob: '', email: '' };

    const [ownersState, setOwnersState] = React.useState({
        fields: initialValues,
        errors: initialValues,
        owners: props.owners || [],
        dobRef: null,
        ssnRef: null,
        activeElement: null,
        showForm: false,
    } as IBusinessOwnersInfoFormState)

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;


            setOwnersState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function onDOBInit(cleave: React.ReactInstance) {
        if (cleave) setOwnersState(prevState => { return { ...prevState, dobRef: cleave } });
    }

    function onSSNInit(cleave: React.ReactInstance) {
        if (cleave) setOwnersState(prevState => { return { ...prevState, ssnRef: cleave } });
    }

    function handleDOBChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target ? e.target.value : '';
        setOwnersState(prevState => { return { ...prevState, fields: { ...prevState.fields, dob: value } } });
    }

    function handleSSNChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target ? e.target.rawValue || e.target.value : '';
        setOwnersState(prevState => { return { ...prevState, fields: { ...prevState.fields, ssn: value } } });
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            handleAddOwner();
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for add business owner step`, fields: ownersState.errors });
        }
    }

    function handleContinue() {
        if (formHasOwner() && formPercentageIsValid()) {
            store.dispatch({ type: 'owners', payload: [...ownersState.owners] });
            props.history.push('/instant/processing');
        } else {
            // @ts-ignore
            window._LTracker.push(`User failed validation for business owners step`);
        }
    }

    function handleAddOwner() {
        const newOwner: BusinessOwnerType = { ...ownersState.fields };
        newOwner.id = uuid();

        // @ts-ignore
        ownersState.dobRef.setRawValue(null);
        // @ts-ignore
        ownersState.ssnRef.setRawValue(null);

        setOwnersState(prevState => {
            return {
                ...prevState,
                fields: initialValues,
                errors: initialValues,
                owners: [...prevState.owners, newOwner],
                showForm: !ownersState.showForm,
                activeElement: null,
            }
        });
    }

    function handleDestroyOwner(val: string) {
        const filtered = ownersState.owners.filter((owner: BusinessOwnerType) => {
            return val !== owner.id;
        });

        setOwnersState(prevState => { return { ...prevState, owners: filtered } })
    }

    function resetForm(e: React.MouseEvent) {
        e.preventDefault();

        setOwnersState(prevState => {
            return {
                ...prevState,
                fields: initialValues,
                errors: initialValues,
                showForm: !ownersState.showForm,
                activeElement: null,
            }
        });
    }

    function formIsValid() {
        const { email, zipcode, dob, ssn, percentOwnership } = ownersState.fields;

        const newErrors = {} as BusinessOwnerType;

        if (!emailRegExp.test(email)) {
            newErrors.email = 'Invalid Email Address';
        }

        if (!wholeNumRegExp.test(zipcode)) {
            newErrors.zipcode = 'Invalid: Must be a number';
        }

        if (dob.length !== 10) {
            newErrors.dob = 'Invalid Date of Birth. Format must follow MM-DD-YYYY';
        }

        const dateNow = Date.now() - new Date(dob).getTime();
        const age = Math.abs(new Date(dateNow).getUTCFullYear() - 1970);

        if (age < 18 || age > 120) {
            newErrors.dob = 'Invalid Date of Birth. You must be at least 18 and still alive';
        }

        if (ssn.length !== 9) {
            newErrors.ssn = 'Invalid Social Security Number. Format must follow XXX-XX-XXXX';
        }

        if (ssn.length === 9 && formHasDuplicateOwner()) {
            newErrors.ssn = 'Duplicate Owner SSN';
        }

        if (!wholeNumRegExp.test(percentOwnership)) {
            newErrors.percentOwnership = 'Round to the nearest number';
        }

        setOwnersState(prevState => { return { ...prevState, errors: newErrors } });
        return Object.values(newErrors).every(field => field === '');
    }

    function formHasOwner() {
        return ownersState.owners.length !== 0;
    }

    function formHasDuplicateOwner() {
        return ownersState.owners.filter(owner => owner.ssn === ownersState.fields.ssn).length !== 0;
    }

    function formPercentageIsValid() {
        const totalPercentage = ownersState.owners.reduce((acc, next) => {
            return acc + +next.percentOwnership;
        }, 0);

        return totalPercentage <= 100;
    }

    function elementActive(e) {
        const name = e.target ? e.target.name : '';
        setOwnersState(prevState => { return { ...prevState, activeElement: name } })
    }

    function elementBlur(e) {
        setOwnersState(prevState => { return { ...prevState, activeElement: null } })
    }

    function handleShowForm() {
        setOwnersState(prevState => { return { ...prevState, showForm: !ownersState.showForm } })
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;

            setOwnersState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    return {
        ownerState: ownersState,
        handleChange,
        onDOBInit,
        onSSNInit,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        handleContinue,
        handleDestroyOwner,
        resetForm,
        formHasOwner,
        formPercentageIsValid,
        elementActive,
        elementBlur,
        handleShowForm,
        handleCleaveChange
    }
}

const Owners = (props: ViewProps) => {
    const {
        ownerState,
        handleChange,
        onDOBInit,
        onSSNInit,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        handleContinue,
        handleDestroyOwner,
        resetForm,
        formHasOwner,
        formPercentageIsValid,
        elementActive,
        elementBlur,
        handleShowForm,
        handleCleaveChange
    } = useOwnersState(props);

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={ownerState.activeElement} />
        <form className="gp-form" id="#businessOwners" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <Directions />
                {
                    !ownerState.showForm
                        ? <>
                            <div className="flex w-100 bb mt3 items-center pb2">
                                <p>Owners</p>
                                <button className="ml-auto pointer h2 pv1 ph2 f6 b" onClick={handleShowForm}>+ Add Owner</button>
                            </div>

                        </>
                        : <>
                            <div className="flex w-100 bb mt3 items-center pb2">
                                <p>Adding Owner</p>
                                <button className="ml-auto pointer h2 pv1 ph2 f6 b" onClick={resetForm}>Cancel</button>
                            </div>
                        </>
                }
                {
                    !ownerState.showForm
                        ? ownerState.owners.length > 5 ? <div className="error-message">Cannot add more than 5 Owners</div> : <span className="ml-auto f7">Click here to add Owners or Signers</span>
                        : <span className="ml-auto f7">Click here to cancel adding this owner</span>
                }

                {
                    ownerState.showForm &&
                    <fieldset className="form-group">
                        <BusinessOwnerForm
                            onDOBInit={onDOBInit}
                            onSSNInit={onSSNInit}
                            handleChange={handleChange}
                            handleDOBChange={handleDOBChange}
                            handleSSNChange={handleSSNChange}
                            handleFocus={elementActive}
                            handleBlur={elementBlur}
                            handleCleaveChange={handleCleaveChange}
                            fields={ownerState.fields}
                            errors={ownerState.errors}
                            percentOwnership
                            type="Business"
                        />
                        <button className="ml-auto" type="submit" disabled={ownerState.owners.length >= 5 || !formPercentageIsValid()}>Add</button>
                    </fieldset>
                }
                {
                    !ownerState.showForm &&
                    <div className="flex flex-wrap mt3">
                        {ownerState.owners.map((owner: IBusinessOwnerCard) => {
                            return (
                                <BusinessOwnerCard key={owner.id} {...owner} destroyOwner={handleDestroyOwner} />
                            )
                        })}
                    </div>
                }
                {
                    !ownerState.showForm &&
                    <button type="button" className="submit ml-auto mt-auto self-end" onClick={handleContinue} disabled={!formHasOwner() || !formPercentageIsValid() || loading}>Continue</button>
                }
                {!formPercentageIsValid() && <div className="error-message">Total Owners' Percentage exceeds 100%</div>}

            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ owners: state.owners });

export default connect(mapStateToProps)(withRouter(Owners));
