import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm';
import { store } from '../initStore';
import { SignupFields, StoreTypes, UtmParams } from '../../interfaces';
import { phoneRegExp, emailRegExp } from '../../utils/regex';

interface ISignUpState {
    fields: SignupFields;
    errors: SignupFields;
    activeElement: string;
    utmParams: UtmParams;
}

const explainer = () =>
    <>
        <h1>GETTING STARTED</h1>

        <p>First, the basics.</p>
        <p>Here are the documents you’ll need to complete the application:</p>
        <ul className="pl4 ma0 mt2">
            <li>Valid Government ID</li>
            <li>Voided Check</li>
        </ul>
    </>


const useSignUpState = (props: ViewProps) => {
    const initialValues = { dba: '', firstName: '', lastName: '', email: '', phone: '', merchantType: 'business' };
    const initialErrors = { dba: '', firstName: '', lastName: '', email: '', phone: '', merchantType: '' };
    const initialUtmParams = { source: '', medium: '', campaign: '', keyword: '', content: '' };

    const [signUpState, setSignUpState] = React.useState(
        {
            fields: props.signup ? { ...props.signup } : { ...initialValues },
            errors: initialErrors,
            activeElement: null,
            utmParams: { ...initialUtmParams },
        } as ISignUpState
    )

    useEffect(
        () => {
            function getParams() {
                const currentUrl = new URL(window.location.href);
                const partner = currentUrl.searchParams.get('partner');
                const pricingCode = currentUrl.searchParams.get('pricingCode');

                if (partner) store.dispatch({ type: 'SET_PARTNER', payload: { partner }})
                if (pricingCode) store.dispatch({ type: 'SET_PRICING_CODE',payload: { pricingCode } });
            }
            // calling function in order to keep the function localized and to remove es-lint React warnings
            getParams();
        }, []
    )

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;

            setSignUpState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.value || e.currentTarget.value;


            setSignUpState(prevState => { return { ...prevState, fields: { ...prevState.fields, [name]: value } } });
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (formIsValid()) {
            store.dispatch({
                type: 'signup',
                payload: {
                    signup: { ...signUpState.fields },
                    utmParams: { ...signUpState.utmParams },
                },
            });

            signUpState.fields.merchantType === 'business'
                ? props.history.push('/instant/business/dba')
                : props.history.push('/instant/personal/dba');
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for signup step`, fields: signUpState.errors });
        }
    }

    function formIsValid() {
        const {
            fields: {
                email,
                phone,
            },
        } = signUpState;

        const newErrors = {} as SignupFields;

        if (!emailRegExp.test(email)) {
            newErrors.email = 'Invalid Email Address';
        }

        if (!phoneRegExp.test(phone)) {
            newErrors.phone = 'Invalid Phone Number';
        }

        setSignUpState(prevState => { return { ...prevState, errors: newErrors } });

        return Object.values(newErrors).every(field => field === '');
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';

        setSignUpState(prevState => { return { ...prevState, activeElement: name } });
    }

    return {
        signUpState,
        handleChange,
        handleCleaveChange,
        handleSubmit,
        elementActive
    }
}

const Signup = (props: ViewProps) => {
    const {
        signUpState,
        handleChange,
        handleCleaveChange,
        handleSubmit,
        elementActive
    } = useSignUpState(props);
    const { dba, firstName, lastName, email, phone, merchantType } = signUpState.fields;

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside explainer={explainer()} field={signUpState.activeElement} />
        <form className="gp-form" id="#signup" onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={0} />

                <div className="form-group">
                    <div className="input-full">
                        <label>Doing Business As</label>
                        <input onFocus={elementActive} value={dba} onChange={handleChange} type="text" name="dba" maxLength={30} required />
                    </div>

                    <div className="input-half">
                        <label className="db">First Name</label>
                        <input onFocus={elementActive} value={firstName} onChange={handleChange} type="text" name="firstName" maxLength={40} required />
                    </div>

                    <div className="input-half">
                        <label className="db">Last Name</label>
                        <input onFocus={elementActive} value={lastName} onChange={handleChange} type="text" name="lastName" maxLength={80} required />
                    </div>

                    <div className="input-half">
                        <label className="db">Personal Email</label>
                        <input onFocus={elementActive} value={email} onChange={handleChange} name="email" type="email" maxLength={40} required />
                        {signUpState.errors.email && <div className="error-message">{signUpState.errors.email}</div>}
                    </div>

                    <div className="input-half">
                        <label className="db">Personal Phone</label>
                        <Cleave
                            name="phone"
                            value={phone}
                            options={{ phone: true, phoneRegionCode: 'us', blocks: [3, 3, 4], delimiter: '-' }}
                            onChange={handleCleaveChange}
                            onFocus={elementActive}
                            required
                            inputMode="numeric" />
                        {signUpState.errors.phone && <div className="error-message">{signUpState.errors.phone}</div>}
                    </div>

                    <div className="mt2">
                        <IsOrRadioForm
                            label="Are your business taxes paid through your Social Security Number (SSN/TIN) or a separate Federal Tax ID (EIN/TIN)?"
                            name="merchantType"
                            values={['sole proprietorship', 'business']}
                            labels={['SSN', 'EIN/TIN']}
                            selected={merchantType}
                            onFocus={elementActive}
                            className="w-50"
                            handleChange={handleChange} />
                    </div>
                </div>

                <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}

const mapStateToProps = state => ({ signup: state.signup });

export default connect(mapStateToProps)(withRouter(Signup));
