import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { store } from '../initStore';
import { StoreTypes } from '../../interfaces';
import { createAccountFromStore, signingDocuments } from '../../api';

import redirect from '../../redirect'


interface ICreateDocusignState {
    creatingAccount: boolean;
    timer: any;
    counter: number;
    loading: boolean;
}
const explainer = () =>
    <>
        <h1>WE ARE PREPARING YOUR APPLICATION</h1>
        <p>In a moment, you will be presented with a DocuSign. Please sign this.</p>
    </>


export function useInterval(callback, delay: null | number) {
    const savedCallback = React.useRef();

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            //@ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}


const CreateApp = (props: ViewProps) => {
    const [checkoutState, setCheckoutState] = useState(
        {
            // @ts-ignore
            creatingAccount: false,
            timer: null,
            counter: 0,
            loading: false,
        } as ICreateDocusignState
    )

    const createAppId = async () => {
        try {
            const { account } = store.getState() as StoreTypes;

            if (!account || !account.submitted) {
                const res = await createAccountFromStore();
                store.dispatch({ type: 'account', payload: { id: res.appId, submitted: true } });

                // @ts-ignore
                window._LTracker.push(`Successfully created account ${res.appId}`);
            }

        } catch (e) {
            console.error('err:', e);
            setCheckoutState(prevState => { return { ...prevState, timer: null } })
            store.dispatch({ type: 'loading' });
            redirect(process.env.REACT_APP_INSTANT_ERROR);
        }
    }

    async function getSigningDocuments() {
        // Retry to get the signing URL for n times.
        if (checkoutState.counter < 121) {
            if (checkoutState.counter === 30 || checkoutState.counter === 60 || checkoutState.counter === 90 || checkoutState.counter === 120) {
                // @ts-ignore
                window._LTracker.push('Attempting to fetching the signing documents');

                try {
                    const { account } = store.getState() as StoreTypes;

                    const { signingUrl } = await signingDocuments({ id: account.id });

                    setCheckoutState(prevState => { return { ...prevState, timer: null } });

                    // @ts-ignore
                    window._LTracker.push(`Redirecting user to signing documents ${account.id}`);
                    redirect(signingUrl);
                } catch (e) {
                    // We can get a 404 here.
                }
            }

            setCheckoutState(prevState => { return { ...prevState, counter: prevState.counter + 1 } })
        } else {
            setCheckoutState(prevState => { return { ...prevState, timer: null } })
            redirect(process.env.REACT_APP_INSTANT_ERROR);
        }
    }

    useEffect(() => {
        createAppId();
        setCheckoutState(prevState => { return { ...prevState, timer: 1000} })
    }, [])

    useInterval(getSigningDocuments, checkoutState.timer)

    return <View>
        <Aside explainer={explainer()} />
        <form className="gp-form">
            <div className="gp-form-elements">
                <StepIndicator pageStep={4} />
                <div className="loading-text">
                    <p>Creating Application:</p>
                    <p>{`${Math.floor(checkoutState.counter * 0.83)}% Completed`}</p>
                </div>
            </div>
        </form>
    </View>;
}


export default withRouter(CreateApp);
